import api from '../api';
import { importFetchedAccounts } from './importer';
import { fetchRelationships } from './accounts';

export const DIRECTORY_FETCH_REQUEST = 'DIRECTORY_FETCH_REQUEST';
export const DIRECTORY_FETCH_SUCCESS = 'DIRECTORY_FETCH_SUCCESS';
export const DIRECTORY_FETCH_FAIL = 'DIRECTORY_FETCH_FAIL';

export const DIRECTORY_EXPAND_REQUEST = 'DIRECTORY_EXPAND_REQUEST';
export const DIRECTORY_EXPAND_SUCCESS = 'DIRECTORY_EXPAND_SUCCESS';
export const DIRECTORY_EXPAND_FAIL = 'DIRECTORY_EXPAND_FAIL';

export const fetchDirectory = params => async (dispatch, getState) => {
  dispatch(fetchDirectoryRequest());

  const LIMIT = 80

  let accounts = []
  let lastFetched = []
  let count = 0
  while (count === 0 || lastFetched.length === LIMIT) {
    try {
      const { data } = await api(getState).get('/api/v1/directory', { params: { ...params, offset: LIMIT * count, limit: LIMIT, local: true } })
      lastFetched = [...data]
      accounts.push(...lastFetched)
      count++
    } catch (err) {
      dispatch(fetchDirectoryFail(err))
      return;
    }
  }


  accounts.sort((a, b) =>
    a.display_name.toLowerCase().replace("the ", '') > b.display_name.toLowerCase().replace("the ", '')
      ? 1 : -1)

  dispatch(importFetchedAccounts(accounts));
  dispatch(fetchDirectorySuccess(accounts));
  dispatch(fetchRelationships(accounts.map(x => x.id)));

  // api(getState).get('/api/v1/directory', { params: { ...params, limit: 80, local: true } }).then(({ data }) => {
  //   dispatch(importFetchedAccounts(data));
  //   dispatch(fetchDirectorySuccess(data));
  //   dispatch(fetchRelationships(data.map(x => x.id)));
  // }).catch(error => dispatch(fetchDirectoryFail(error)));
};

export const fetchDirectoryRequest = () => ({
  type: DIRECTORY_FETCH_REQUEST,
});

export const fetchDirectorySuccess = accounts => ({
  type: DIRECTORY_FETCH_SUCCESS,
  accounts,
});

export const fetchDirectoryFail = error => ({
  type: DIRECTORY_FETCH_FAIL,
  error,
});

export const expandDirectory = params => (dispatch, getState) => {
  dispatch(expandDirectoryRequest());

  const loadedItems = getState().getIn(['user_lists', 'directory', 'items']).size;

  api(getState).get('/api/v1/directory', { params: { ...params, offset: loadedItems, limit: 20 } }).then(({ data }) => {
    dispatch(importFetchedAccounts(data));
    dispatch(expandDirectorySuccess(data));
    dispatch(fetchRelationships(data.map(x => x.id)));
  }).catch(error => dispatch(expandDirectoryFail(error)));
};

export const expandDirectoryRequest = () => ({
  type: DIRECTORY_EXPAND_REQUEST,
});

export const expandDirectorySuccess = accounts => ({
  type: DIRECTORY_EXPAND_SUCCESS,
  accounts,
});

export const expandDirectoryFail = error => ({
  type: DIRECTORY_EXPAND_FAIL,
  error,
});
